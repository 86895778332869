@page {
  margin: 5mm;
}

@media print {
  body#app-layout {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #000;
    background-color: #fff;
    padding: 0;
    margin: 15mm 8mm;
  }
  .order-label {
    width: 9.5cm;
    height: 6.75cm;
    border: 2px solid #000;
    border-radius: 10px;
    margin: 0;
    padding: 5mm;
  }
  p.p-label {
    margin-top: 10mm;
    margin-bottom: 5mm;
  }
  h1.p-label, h2.p-label, h3.p-label, h4.p-label, h5.p-label, h6.p-label {
    margin-top: 20mm;
    margin-bottom: 10mm;
  }
  .action-bar, .action-bar-hidden, .pace, .navbar {
    display: none;
  }
  nav#sidebar {
    display: none;
  }
  div #cboxOverlay, div #colorbox, div #nprogress {
    display: none;
  }
  div #main-content {
    min-height: 0;
    top: 0;
    margin: 0;
    padding: 0;
  }
  div #main-content .container-fluid {
    margin-top: 10px;
    margin-right: 12.5px;
    margin-bottom: initial;
  }
  .sidebar-large #wrapper {
    margin: 0;
    min-height: 0;
    padding: 0;
  }
  .printable.container-fluid {
    padding: 0;
  }
  .meditron .technician.printer .panel .order-label {
    padding: 10px;
    border: solid #000 2px;
    border-radius: 10px;
    width: 525px;
    color: #000;
  }
}
