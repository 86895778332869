/**
 *  Bootstrap print 3.x-1.2 for Bootstrap v3.
 *
 *  Mange grids using Bootstrap v3 for printed media.
 *  This will help controlling grid columns size on printed pages.
 *  https://github.com/Natshah/bootstrap-print
 *
 */

@media print {

	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.container-fluid {
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}

	.hidden-print {
		display: none !important;
	}

	.row {
		margin-right: -15px;
		margin-left: -15px;
	}

	.col-p-1, .col-p-2, .col-p-3, .col-p-4, .col-p-5, .col-p-6, .col-p-7, .col-p-8, .col-p-9, .col-p-10, .col-p-11, .col-p-12 {
		float: left;
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.col-p-12 {
		width: 100%;
	}
	.col-p-11 {
		width: 91.66666667%;
	}
	.col-p-10 {
		width: 83.33333333%;
	}
	.col-p-9 {
		width: 75%;
	}
	.col-p-8 {
		width: 66.66666667%;
	}
	.col-p-7 {
		width: 58.33333333%;
	}
	.col-p-6 {
		width: 50%;
	}
	.col-p-5 {
		width: 41.66666667%;
	}
	.col-p-4 {
		width: 33.33333333%;
	}
	.col-p-3 {
		width: 25%;
	}
	.col-p-2 {
		width: 16.66666667%;
	}
	.col-p-1 {
		width: 8.33333333%;
	}

	.col-p-pull-12 {
		right: 100%;
	}
	.col-p-pull-11 {
		right: 91.66666667%;
	}
	.col-p-pull-10 {
		right: 83.33333333%;
	}
	.col-p-pull-9 {
		right: 75%;
	}
	.col-p-pull-8 {
		right: 66.66666667%;
	}
	.col-p-pull-7 {
		right: 58.33333333%;
	}
	.col-p-pull-6 {
		right: 50%;
	}
	.col-p-pull-5 {
		right: 41.66666667%;
	}
	.col-p-pull-4 {
		right: 33.33333333%;
	}
	.col-p-pull-3 {
		right: 25%;
	}
	.col-p-pull-2 {
		right: 16.66666667%;
	}
	.col-p-pull-1 {
		right: 8.33333333%;
	}
	.col-p-pull-0 {
		right: auto;
	}
	.col-p-push-12 {
		left: 100%;
	}

	.col-p-push-11 {
		left: 91.66666667%;
	}
	.col-p-push-10 {
		left: 83.33333333%;
	}
	.col-p-push-9 {
		left: 75%;
	}
	.col-p-push-8 {
		left: 66.66666667%;
	}
	.col-p-push-7 {
		left: 58.33333333%;
	}
	.col-p-push-6 {
		left: 50%;
	}
	.col-p-push-5 {
		left: 41.66666667%;
	}
	.col-p-push-4 {
		left: 33.33333333%;
	}
	.col-p-push-3 {
		left: 25%;
	}
	.col-p-push-2 {
		left: 16.66666667%;
	}
	.col-p-push-1 {
		left: 8.33333333%;
	}
	.col-p-push-0 {
		left: auto;
	}

	.col-p-offset-12 {
		margin-left: 100%;
	}
	.col-p-offset-11 {
		margin-left: 91.66666667%;
	}
	.col-p-offset-10 {
		margin-left: 83.33333333%;
	}
	.col-p-offset-9 {
		margin-left: 75%;
	}
	.col-p-offset-8 {
		margin-left: 66.66666667%;
	}
	.col-p-offset-7 {
		margin-left: 58.33333333%;
	}
	.col-p-offset-6 {
		margin-left: 50%;
	}
	.col-p-offset-5 {
		margin-left: 41.66666667%;
	}
	.col-p-offset-4 {
		margin-left: 33.33333333%;
	}
	.col-p-offset-3 {
		margin-left: 25%;
	}
	.col-p-offset-2 {
		margin-left: 16.66666667%;
	}
	.col-p-offset-1 {
		margin-left: 8.33333333%;
	}
	.col-p-offset-0 {
		margin-left: 0%;
	}

	/* Always insert a page break after the element */
	.always-page-break-after,
	.always-pba {
		page-break-after: always;
	}

	/* Avoid page break after the element (if possible) */
	.avoid-page-break-after,
	.avoid-pba {
		page-break-after: avoid;
	}

	/* Insert page breaks after the element so that the next page is formatted as a left page */
	.left-page-break-after,
	.left-pba {
		page-break-after: left;
	}

	/* Insert page breaks after the element so that the next page is formatted as a right page */
	.right-page-break-after,
	.right-pba {
		page-break-after: right;
	}

	/* Always insert a page break before the element */
	.always-page-break-before,
	.always-pbb {
		page-break-before: always;
	}

	/* Avoid page break before the element (if possible) */
	.avoid-page-break-before,
	.avoid-pbb {
		page-break-before: avoid;
	}

	/* Insert page breaks before the element so that the next page is formatted as a left page */
	.left-page-break-before,
	.left-pbb {
		page-break-before: left;
	}

	/* Insert page breaks before the element so that the next page is formatted as a right page */
	.right-page-break-before,
	.right-pbb {
		page-break-before: right;
	}

	/* Avoid page break inside the element (if possible) */
	.avoid-page-break-inside,
	.avoid-pbi {
		page-break-inside: avoid;
	}
}